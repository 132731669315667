import { useEffect, useState } from "react";

import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import {
  PlasmicCanvasHost,
  PlasmicComponent,
  PlasmicRootProvider,
  useSelector,
} from "@plasmicapp/loader-react";

import LoadingPage from "./components/custom/LoadingPage";
import { PLASMIC } from "./plasmic-init";
import { ProtectedRoute } from "./services/auth/ProtectedRoute";
import {
  getUserFromLocalStorage,
  isTokenExpired,
} from "./services/utils/localStorage";

// Modify RenderPage to accept `pageName` as a prop
// eslint-disable-next-line react/prop-types
export function RenderPage({ pageName }) {
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState(null);

  useEffect(() => {
    async function load() {
      const pageData = await PLASMIC.maybeFetchComponentData(pageName);
      setPageData(pageData);
      setLoading(false);
    }
    load();
  }, [pageName]);

  if (loading) {
    return <LoadingPage />;
  }
  if (!pageData) {
    return <div>Not found</div>;
  }
  return <PlasmicComponent component={pageName} />;
}

const App = () => {
  var localStorageUser = null;
  const { user, isLoading } = useSelector("auth");
  if (!user?.user) {
    localStorageUser = getUserFromLocalStorage();
  }
  if (isTokenExpired(user?.user || localStorageUser?.user)) {
    localStorageUser = null;
  }
  return (
    <PlasmicRootProvider
      globalContextsProps={{ menuIsOpened: true }}
      loader={PLASMIC}
      user={user?.user || localStorageUser?.user}
      userAuthToken={user?.token || localStorageUser?.token}
      isUserLoading={isLoading}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<RenderPage pageName="Welcome" />} />
          <Route path="/about-us" element={<RenderPage pageName="AboutUs" />} />
          <Route
            path="/recommendations"
            element={<RenderPage pageName="Recommendations" />}
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute
                token={(user?.token || localStorageUser?.token) ?? ""}
              >
                <RenderPage pageName="Profile" />
              </ProtectedRoute>
            }
          />
          <Route
            path="/forbidden"
            element={
              <>
                {user?.user?.isLoggedIn ||
                localStorageUser?.user?.isLoggedIn ? (
                  <Navigate to="/" replace />
                ) : (
                  <RenderPage pageName="Forbidden" />
                )}
              </>
            }
          />
          <Route path="/plasmic-host" element={<PlasmicCanvasHost />} />
        </Routes>
      </BrowserRouter>
    </PlasmicRootProvider>
  );
};

export default App;
